import './style.scss'
import ToggleSwitch from "../../../../component/toggleSwich/ToggleSwich";
import TextEditor from "../../../../../../components/textEditor/TextEditor";
import ColorPicker from "../../../../component/colorPicker/ColorPicker";
import BackgroundChangeRadio from "../../../../component/backgroundChangeRadio/BackgroundChangeRadio";
import React from "react";




const TermsAndConditionsPage = () => {
    return (
        <div className="termsAndConditionsPage">
            <section className="termsAndConditionsPage-heading">
                <h1>Terms and Conditions</h1>
                <ToggleSwitch/>
            </section>
            <TextEditor/>
            <ColorPicker label={''} heading={'Text Color'}/>
            <BackgroundChangeRadio/>
        </div>
        )
}
export default TermsAndConditionsPage;