
import brandLogo from './assets/brandLogo.png'
import spinWheel from './assets/SpinWheel.png'


export  {brandLogo,spinWheel}




export  const cardConst = [
    {
        heading: 'Spin the Wheel',
        secondHeading: 'Spin the Wheel',
        image: spinWheel,
        label: ['ENGAGEMENT','ENGAGEMENT', 'SPIN', 'SPIN'],
        brand:[brandLogo,brandLogo,brandLogo],
        numbers: 10,
    },
    {
        heading: 'Dart it up',
        secondHeading: 'Dart it up',
        image: spinWheel,
        label: ['ENGAGEMENT','ENGAGEMENT'],
        brand:[brandLogo,brandLogo,brandLogo],
        numbers: 10,

    },
    {
        heading: 'Crazy Cricket',
        secondHeading: 'Crazy Cricket',
        image: spinWheel,
        label: ['ENGAGEMENT','ENGAGEMENT'],
        brand:[brandLogo,brandLogo,brandLogo],
        numbers: 10,

    },
    {
        heading: 'Galactic Mission',
        secondHeading: 'Galactic Mission',
        image: spinWheel,
        label: ['ENGAGEMENT','ENGAGEMENT'],
        brand:[brandLogo,brandLogo,brandLogo],
        numbers: 10,

    },
    {
        heading: 'Royal Chess',
        secondHeading: 'Royal Chess',
        image: spinWheel,
        label: ['ENGAGEMENT','ENGAGEMENT'],
        brand:[brandLogo,brandLogo,brandLogo],
        numbers: 10,

    },
    {
        heading: 'Tetris',
        secondHeading: 'Tetris',
        image: spinWheel,
        label: ['ENGAGEMENT','ENGAGEMENT'],
        brand:[brandLogo,brandLogo,brandLogo],
        numbers: 10,

    },
    {
        heading: 'Cars on Fire',
        secondHeading: 'Cars on Fire',
        image: spinWheel,
        label: ['ENGAGEMENT','ENGAGEMENT'],
        brand:[brandLogo,brandLogo,brandLogo],
        numbers: 10,

    },
    {
        heading: 'Tic Tac Toe',
        secondHeading: 'Tic Tac Toe',
        image: spinWheel,
        label: ['ENGAGEMENT','ENGAGEMENT'],
        brand:[brandLogo,brandLogo,brandLogo],
        numbers: 10,

    },
]