import './style.scss'
import {Facebook, Instagram, LinkedIn, logo, Twitter, YouTube} from "../../assets/landingPageAssets";
import InputField from "../../component/inputField/inputField";

export default function Footer() {

    return (
        <div className={'footer-section'}>
            <section className={'footer-left'}>
                <div className={'left-logo'}>
                    <img src={logo} alt={'logo'}/>
                    <h1>Gaming Engagement Platform</h1>
                </div>
                <div className={'left-input'}>
                    <h1>Book a Demo</h1>
                    <InputField inputFieldClass={'footerinputField'} inputLeftClass={'footerinput-left'} inputRightClass={'footerinput-right'}/>
                </div>
            </section>
            <section className={'footer-right'}>
                <div className={'right-upper'}>
                    <img src={Facebook} alt={'facebook'}/>
                    <img src={Twitter} alt={'tweet'}/>
                    <img src={Instagram} alt={'instagram'}/>
                    <img src={LinkedIn} alt={'linkedin'}/>
                    <img src={YouTube} alt={'youtube'}/>
                </div>
                <div className={'right-lower'}>
                    <span>All Rights Reserved</span> | <span>Terms and Conditions</span> | <span>Privacy Policy</span>
                </div>

            </section>

        </div>
    )
}