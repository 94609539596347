import './style.scss'
import ToggleSwitch from "../../../../component/toggleSwich/ToggleSwich";
import ImageInput from "../../../../component/imageInput/ImageInput";
import TextWithColor from "../../../../component/textWithColor/TextWithColor";
import ColorPicker from "../../../../component/colorPicker/ColorPicker";
import React from "react";
import HeadingToggle from "../../../../component/headingToggle/HeadingToggle";




const OtpPage = () => {
  return(
      <div className="otpPage">
          <section className="otpPage-heading">
              <h1>OTP Page</h1>
              <ToggleSwitch/>
          </section>
          <ImageInput images={[
              {
                  label: "Brand  Logo",
                  image: ""
              },
              {
                  label: "Creative Image",
                  image: ""
              },
          ]}/>
          <TextWithColor heading={'Heading'} label={'Text'} children={<ColorPicker label={'Color'} heading={''}/>}/>
          <TextWithColor heading={'OTP Field'} label={'Label Text'} children={''}/>
          <TextWithColor heading={'Button'} label={'Text'} children={<ColorPicker label={'Color'} heading={''}/>}/>
          <HeadingToggle heading={'Resend OTP'}/>


      </div>
  )
}
export default OtpPage;