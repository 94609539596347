import './style.scss'
import ToggleSwitch from "../../../../component/toggleSwich/ToggleSwich";
import ImageInput from "../../../../component/imageInput/ImageInput";
import TextWithColor from "../../../../component/textWithColor/TextWithColor";
import InputGroups from "../../../../component/inputGroups/InputGroups";
import ColorPicker from "../../../../component/colorPicker/ColorPicker";
import React from "react";
import HeadingToggle from "../../../../component/headingToggle/HeadingToggle";




const RegistrationPage = () => {
  return(
      <div className="registerPage">
          <section className="registerPage-heading">
              <h1>Registration Page</h1>
              <ToggleSwitch/>
          </section>
          <ImageInput images={[
              {
                  label: "Brand  Logo",
                  image: ""
              },
              {
                  label: "Creative Image",
                  image: ""
              },
          ]}/>
          <TextWithColor heading={'Heading'} label={'Text'} children={<ColorPicker label={'Color'} heading={''}/>}/>
          <InputGroups/>
          <HeadingToggle heading={'Terms & Conditions'}/>
          <HeadingToggle heading={'Consent'}/>
      </div>
  )
}

export default RegistrationPage;