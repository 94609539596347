import './style.scss'
import {ladderSectionCardConst} from "../../../../constants/landingPageConsts";
import LadderSectionCard from "../../component/ladderSectionCard/LadderSectionCard";


export default function LadderToUnlock() {

    return(
        <div className={'ladderToUnlock-section'}>
            <section className={'ladder-heading'}>
                <h1>Ladder to Unlock Your Potential</h1>
            </section>
            <section className={'ladder-content'}>
                {
                    ladderSectionCardConst.map(({image,heading,content,alt}) =>(
                        <LadderSectionCard image={image} heading={heading} content={content} alt={alt} key={alt}/>
                    ))
                }
            </section>
        </div>
    )
}