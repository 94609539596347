import './style.scss'
import ToggleSwitch from "../../../../component/toggleSwich/ToggleSwich";
import ImageInput from "../../../../component/imageInput/ImageInput";
import TextWithColor from "../../../../component/textWithColor/TextWithColor";
import ColorPicker from "../../../../component/colorPicker/ColorPicker";
import React from "react";
import HeadingToggle from "../../../../component/headingToggle/HeadingToggle";
import BackgroundChangeRadio from "../../../../component/backgroundChangeRadio/BackgroundChangeRadio";


const GamePage = () => {

    return (
        <div className="gamePage">
            <section className="gamePage-heading">
                <h1>Game Page</h1>
                <ToggleSwitch/>
            </section>
            <ImageInput images={[
                {
                    label: "Brand  Logo",
                    image: ""
                }
            ]}/>
            <TextWithColor heading={'Heading'} label={'Text'} children={<ColorPicker label={'Color'} heading={''}/>}/>
            <TextWithColor heading={'Wheel  Options'} label={'Enter number of Options'} children={''}/>
            <HeadingToggle heading={'Upload predesigned image with options'}/>
            <TextWithColor heading={'Number of Retry allowed '} label={'Number of Retry'} children={''}/>
            <TextWithColor heading={'Button'} label={'Text'} children={<ColorPicker label={'Color'} heading={''}/>}/>
            <BackgroundChangeRadio/>
            <HeadingToggle heading={'Hamburger'}/>

        </div>
    )
}


export default GamePage;