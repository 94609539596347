import {combineReducers, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {InputGroupCardInterface} from "../../../interface/InputGroupCardInterface";


const textWithColorSlice = createSlice({
    name: "textWithColor",
    initialState: {
        text: "",
        color: "#000000",
    },
    reducers: {
        // This reducer will be called when the text input changes
        textChange: (state, action: PayloadAction<string>) => {
            state.text = action.payload;
        },
        // This reducer will be called when the color input changes
        colorChange: (state, action: PayloadAction<string>) => {
            state.color = action.payload;
        },
    },
});


const imageData: [{}] = [{
    label: "",
    image: ""
}];

const imagesSlice = createSlice({
    name: "images",

    initialState: imageData,
    reducers: {
        addImage(state, action) {

            state.push(action.payload);
        },
        removeImage(state, action) {
            state.splice(action.payload.index, 1);
        },
    },
});

interface inputGroupCard {
    counter: number[],
    data: InputGroupCardInterface[]
}

const inputGroupCardConst: inputGroupCard = {
    counter: [0],
    data: []
}

const inputGroupCardSlice = createSlice({
        name: 'inputGroupCard',
        initialState: inputGroupCardConst,
        reducers: {
            addFieldReducer(state) {
                const data = state.counter.length;
                state.counter.push(data);


            },
            DeleteCardReducer(state, action) {
                const indexToDelete = action.payload;

                state.counter = state.counter.filter((_, index) => index !== indexToDelete);
            },
            swapUpReducer(state, action: PayloadAction<any, any>) {
                let index = action.payload
                if (index === 0) {
                    console.log("don't swap up")
                } else {
                    console.log("swapping")
                    let temp = state.counter[index - 1];
                    state.counter[index - 1] = state.counter[index];
                    state.counter[index] = temp;
                    console.log("completed");
                }
            }
        }
    }
)

interface hamburgerMenu {
    hamburgerMenuCounter: number[],
}

const hamburgerMenuConst: hamburgerMenu = {
    hamburgerMenuCounter: [1]
}

const hamburgerMenuGroupsSlice = createSlice({
    name: 'hamburgerMenu',
    initialState: hamburgerMenuConst,
    reducers: {
        hamburgerMenuAddFieldReducer(state) {
            const data = state.hamburgerMenuCounter.length;
            state.hamburgerMenuCounter.push(data);


        },
    }
})


interface howToPlayMenu {
    howToPlayMenuCounter: number[],
}

const howToPlayMenuMenuConst: howToPlayMenu = {
    howToPlayMenuCounter: [1]
}

const howToPlayMenuGroupsSlice = createSlice({
    name: 'hamburgerMenu',
    initialState: howToPlayMenuMenuConst,
    reducers: {
        howToPlayMenuReducer(state) {
            const data = state.howToPlayMenuCounter.length;
            state.howToPlayMenuCounter.push(data);


        },
    }
})


export const {textChange, colorChange} = textWithColorSlice.actions;
export const {addImage, removeImage} = imagesSlice.actions;
export const {addFieldReducer, swapUpReducer, DeleteCardReducer} = inputGroupCardSlice.actions;
export const {hamburgerMenuAddFieldReducer} = hamburgerMenuGroupsSlice.actions;
export const {howToPlayMenuReducer} = howToPlayMenuGroupsSlice.actions;

const GameCustomisationContentReducer = combineReducers({
    textWithColorSlice: textWithColorSlice.reducer,
    imagesSlice: imagesSlice.reducer,
    inputGroupCardSlice: inputGroupCardSlice.reducer,
    hamburgerMenuGroupsSlice: hamburgerMenuGroupsSlice.reducer,
    howToPlayMenuGroupsSlice: howToPlayMenuGroupsSlice.reducer,
})
export default GameCustomisationContentReducer;

