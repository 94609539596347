import './style.scss'

interface SmallPhoneCardProps {
    src: string;
    heading: string;
}

const SmallPhoneCard = ({src, heading}: SmallPhoneCardProps) => {

    return (
        <div className="smallPhoneCard-wrapper">
            <section className="smallPhoneCard">
                <div className="smallPhoneCard-image">
                    <img src={src} alt={'mobile'}/>
                </div>
            </section>
            <section className="smallPhoneCard-heading">
                <h1>{heading}</h1>
            </section>
        </div>
    )
}


export default SmallPhoneCard;