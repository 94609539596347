export const ROUTES = {
    LANDING_PAGE: "/",
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    CAMPAIGNS: "/dashboard/campaigns",
    GAME_LIBRARY:"/dashboard/game_library",
    CAMPAIGN:"/dashboard/campaign",
    REPORTS: "/dashboard/reports",
    SETTING:"/dashboard/setting",
    GAME_CUSTOMISATION_PAGE:"/dashboard/gameCustomizationPage"
}