import './style.scss'
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../state/store/Store";
import {
    textChange
} from "../../../../state/features/gameCustomisatiomContentSlice/GameCustomisationContentSlice";

import {ReactNode} from "react";

interface textWithColorProps{
    heading: string,
    label: string,
    children: ReactNode
}

const TextWithColor = ({heading,children,label}:textWithColorProps) => {

    const textWithColorState = useSelector((state: RootState) => state.GameCustomisationContentSlice.textWithColorSlice);
    const dispatch = useDispatch<AppDispatch>()


    return (
        <div className='textWithColor-wrapper'>
            <div className='textWithColor'>
                <h1>{heading}</h1>
                <section className='textWithColor-field'>
                    <div className='textWith'>
                        <label htmlFor='text'>{label}</label>
                        <input
                            type="text"
                            value={textWithColorState.text}
                            onChange={(event) => {
                                dispatch((textChange(event.target.value)));
                            }}
                        />
                    </div>
                    {children}
                </section>
            </div>

        </div>
    )
}

export default TextWithColor;