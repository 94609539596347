import {createBrowserRouter} from "react-router-dom";
import {ROUTES} from "../lib/consts";
import GameCardSection from "../pages/dashbordPage/sections/gameCardSection/GameCardSection";
import Dashboard from "../pages/dashbordPage/Dashboard";
import LandingPage from "../pages/landingPage/LandingPage";
import CampaignsPage from "../pages/dashbordPage/sections/campaignsSection/CampaignsPage";
import GameCustomisationPage from "../pages/gameCustomizationPage/GameCustomisationPage";

const router = createBrowserRouter(
    [
        {
            path: ROUTES.LANDING_PAGE,
            element: <LandingPage/>,
        },
        {
            path: ROUTES.DASHBOARD,
            element: (
                <Dashboard/>
            ),
            children: [
                {
                    index: true,
                    element: (
                        <GameCardSection/>
                    ),
                },
                {
                    path: ROUTES.CAMPAIGN,
                    element: (
                        <GameCardSection/>
                    ),
                },
                {
                    path: ROUTES.GAME_LIBRARY,
                    element: (
                        <GameCardSection/>
                    ),

                },
                {
                    path: ROUTES.CAMPAIGNS,
                    element: (
                        <CampaignsPage/>
                    ),
                },
                {
                    path: ROUTES.REPORTS,
                    element: (
                        <GameCardSection/>
                    ),
                },
                {
                    path: ROUTES.SETTING,
                    element: (
                        <GameCardSection/>
                    ),
                },
                {
                    path: ROUTES.GAME_CUSTOMISATION_PAGE,
                    element: (
                        <GameCustomisationPage/>
                    ),
                },

            ],
        },
        {
         path: ROUTES.GAME_CUSTOMISATION_PAGE,
         element:<GameCustomisationPage/>
        },
        {
            path: "*",
            element: (<h1>Error</h1>),
        },
    ],
    {basename: "/"}
);

export default router;
