import './style.scss'

type ladderSectionCardProps = {
    image: string,
    heading: string,
    content: string,
    alt: string,
}

export default function LadderSectionCard({image, heading, content, alt}: ladderSectionCardProps) {

    return (
        <div className={'ladderSectionCard'}>
            <section className={'ladderSectionCard-image'}>
                <img src={image} alt={alt}/>
            </section>
            <section className={'ladderSectionCard-content'}>
                <h1>{heading}</h1>
                <p>{content}</p>
            </section>
        </div>
    )
}