import {integration, onboard, support} from "./images";

export const ladderSectionCardConst = [
    {
        image: integration,
        heading: 'Easy and Fast Integrations',
        content: 'You can quickly launch any campaign to your web and mobile app in seconds',
        alt:'integration',
    },
    {
        image: onboard,
        heading: 'Quick Onboarding',
        content: 'We will set you up on platform in no time',
        alt:'onboard',

    },
    {
        image: support,
        heading: 'All around Support',
        content: 'Our team goes extra mile to support your needs',
        alt:'support',

    },
]