import './style.scss'
import {ladder} from "../../assets/landingPageAssets";

export default function ReachYourNorth() {

    return(
        <div className={'reachYourNorth-section'}>
            <h1>Reach your north star with PlayVerra</h1>
            <img src={ladder} alt={'ladder'}/>
        </div>
    )
}