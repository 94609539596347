import './style.scss'
import ImageInput from "../../../../component/imageInput/ImageInput";
import TextWithColor from "../../../../component/textWithColor/TextWithColor";
import ColorPicker from "../../../../component/colorPicker/ColorPicker";
import BackgroundChangeRadio from "../../../../component/backgroundChangeRadio/BackgroundChangeRadio";
import React from "react";


const WinnerPage = () => {
    return (
        <div className="winnerPage">
            <section className="winnerPage-heading">
                <h1>Winner Page</h1>
            </section>
            <ImageInput images={[
                {
                    label: "Brand  Logo",
                    image: ""
                }
            ]}/>
            <TextWithColor heading={'Heading'} label={'Text'} children={<ColorPicker label={'Color'} heading={''}/>}/>
            <BackgroundChangeRadio/>

        </div>
    )
}
export default WinnerPage;