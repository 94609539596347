import './style.scss'
import ToggleSwitch from "../toggleSwich/ToggleSwich";
import {IoIosArrowDown, IoIosArrowUp} from "react-icons/io";
import {RiDeleteBin6Line} from "react-icons/ri";
import React from "react";

interface InputGroupCardProps {
    index: number;
    length: number;
    inputGroupsDispatchFunctions: {
        update: (index: any) => void;
        swapUp: (index: any) => void;
    };
}

const InputGroupCard: React.FC<InputGroupCardProps> = ({index, length, inputGroupsDispatchFunctions}) => {
    const totalCards = length;
    const isFirst = index === 0;
    const isLast = index === totalCards - 1;
    const arrowUpClassName = isFirst ? "inactive" : "";
    const arrowDownClassName = isLast ? "inactive" : "";

    function deleteItem() {
        inputGroupsDispatchFunctions.update(index);
    }

    function swapUp() {
        inputGroupsDispatchFunctions.swapUp(index)
    }

    return (

        <div className="inputGroupCard-wrapper" key={index}>
            <section className="inputGroupCard-input">
                <div>
                    <label htmlFor="">Field </label>
                    <input type="text"/>
                </div>
                <div>
                    <label htmlFor="">Input Type</label>
                    <input type="text"/>
                </div>
                <div>
                    <label htmlFor="">Label Text</label>
                    <input type="text"/>
                </div>

            </section>
            <section className="inputGroupCard-btn">
                <div className="i-g-btn-left">
                    <div className="i-g-btn-left-toggle">
                        <p>Visibility</p>
                        <ToggleSwitch/>
                    </div>
                    <div className="i-g-btn-left-divider"></div>
                    <div className="i-g-btn-left-toggle">
                        <p>Mandatory</p>
                        <ToggleSwitch/>
                    </div>
                    <div className="i-g-btn-left-divider"></div>
                    <div className="inputGroup">
                        <RiDeleteBin6Line onClick={() => deleteItem()}/>
                    </div>
                </div>
                <div className="i-g-btn-right">
                    <IoIosArrowUp className={arrowUpClassName} onClick={() => swapUp()}/>
                    <IoIosArrowDown className={arrowDownClassName}/>
                </div>
            </section>

        </div>

    )
}

export default InputGroupCard;