import './style.scss'
import GameCustomisationHeader from "./section/gameCustomisationHeader/GameCustomisationHeader";
import GameCustomisationContent from "./section/gameCustomisatiomContent/GameCustomisationContent";


export default function GameCustomisationPage() {

    return (
        <div className={'gameCustomizationPage'}>
            <GameCustomisationHeader/>
            <GameCustomisationContent/>
        </div>
    )
}