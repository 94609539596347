import './style.scss'
import BrandLogoLoop from "./component/brandLogoLoop/BrandLogoLoop";
import LabelLoop from "./component/labelLoop/LabelLoop";
import {Link} from "react-router-dom";

interface GameLibraryCardProps  {
    src: string,
    heading: string,
    secondHeading: string,
    label: string[],
    logo: string[],
    brandCount: number,

}

export default function GameLibraryCard({
                                            src,
                                            heading,
                                            secondHeading,
                                            label,
                                            brandCount,
                                            logo,
                                        }: GameLibraryCardProps) {



    return (

        <div className={'gameLibraryCard'}>
            <section className={'gameLibraryCardFront'}>
                <section className={'gameLibraryCardLogo'}>
                    <img src={src} alt={'image'}/>
                </section>
                <section className={'gameLibraryCardHeading'}>
                    <h1>{heading}</h1>
                </section>
                <section className={'gameLibraryCardLabel'}>
                    <LabelLoop labels={label}/>
                </section>
                <section className={'brandDetails'}>
                    <div className={'brandDetails-logo'}>
                        <BrandLogoLoop image={logo}/>
                    </div>
                    <div className={'brandDetails-count'}>
                        <p>{brandCount} Brands use this</p>
                    </div>
                </section>
            </section>

            <section className={'gameLibraryCardBack'}>
                <h1>{secondHeading}</h1>
                <Link to={'/gameCustomizationPage'}><button type="button">Select</button></Link>
                <button type="button">Preview</button>
            </section>

        </div>
    )
}

