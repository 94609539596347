import './style.scss'

type stepComponentProps = {
    steps: string[];
    activeStep: number;
    onStepClick: (step: number) => void;
};

export default function CampaignStepper({steps, activeStep, onStepClick}: stepComponentProps) {

    return (
        <div className="campaignStepper">
            {steps.map((step, index) => {
                let stepClass = '';
                if (index + 1 < activeStep) {
                    stepClass = 'completed';
                } else if (index + 1 === activeStep) {
                    stepClass = 'active';
                } else {
                    stepClass = 'inactive';
                }

                return (
                    <div
                        key={index}
                        className={`step ${stepClass}`}
                        onClick={() => {
                            if (stepClass === 'completed') {
                                onStepClick(index + 1);
                            }
                        }}
                    >
                        <span>{index + 1}</span>{step}
                    </div>
                );
            })}
        </div>
    );
}

