import './style.scss'
import {Box, Drawer} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close';
import {fb, google} from "../../assets/landingPageAssets";
import {Link} from "react-router-dom";
import React from "react";




type Anchor = "right";
const toggleDrawer = (anchor: Anchor, open: boolean) =>

    (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
    };
export const MuiDrawer = (props: any) => {


    return (
                <Drawer
                    anchor='right'
                    open={props.show}
                    onClose={toggleDrawer("right", false)}

                >

                    <Box
                        role="presentation"
                        p={2}
                        sx={{
                             width: {xs: '92vw', sm: '92vw', md: '92vw', lg: '40vw'},
                             display: 'flex',
                             justifyContent: 'center '
                         }}
                         onClick={toggleDrawer("right", false)}
                         onKeyDown={(event) => toggleDrawer("right", false)(event)}>


                        <div className={'login-div'}>
                            <CloseIcon onClick={() => props.showDrawer(false)}
                                       sx={{fontSize: '35px', cursor: "pointer"}}/>
                            <h1>Login</h1>
                            <section className={'field-section'}>
                                <label htmlFor={'email'}>Email address</label>
                                <input type="email" name="email"/>
                                <label htmlFor={'Password'}>Password</label>
                                <input type="password" name="password"/>
                                <div className={'field-bottom-section'}>
                                    <div className={'field-bottom-left'}>
                                        <input type={'checkbox'} name={'checkbox'}/>
                                        <label htmlFor={'checkbox'} className={'remember-me'}>Remember Me</label>
                                    </div>
                                    <div className={'field-bottom-right'}>
                                        <p>Forgot your password?</p>
                                    </div>
                                </div>
                                <Link to={'/dashboard'}>
                                    <button type="submit" name="login">Login</button>
                                </Link>
                            </section>

                            <section className={'o-login-section'}>
                                <div/>
                                <p>Or Login with</p>
                                <div/>
                            </section>

                            <section className={'logo-section'}>
                                <img src={fb} alt={'facebook'}/>
                                <img src={google} alt={'google'}/>
                            </section>

                        </div>

                    </Box>
                </Drawer>

    )
}


