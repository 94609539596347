import React, { useState } from 'react';
import './style.scss'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';import DialogTitle from '@mui/material/DialogTitle';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import {IconButton} from "@mui/material";

interface TitleDialogProps {
    open: boolean;
    onClose: () => void;
    onUpdateTitle: (newTitle: string) => void;
}

const TitleDialog: React.FC<TitleDialogProps> = ({ open, onClose, onUpdateTitle }) => {
    const [title, setTitle] = useState<string>('');

    const handleUpdate = () => {
        onUpdateTitle(title);
        onClose();
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Enter Game Title</DialogTitle>
            <DialogContent>
                <input
                    type="text"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
            </DialogContent>
            <DialogActions>

                <IconButton onClick={onClose} aria-label="delete" size="large" color="error">
                    <CloseOutlinedIcon />
                </IconButton>

                <IconButton onClick={handleUpdate} aria-label="delete" size="large" color="success">
                    <DoneOutlinedIcon/>
                </IconButton>
            </DialogActions>
        </Dialog>
    );
};

export default TitleDialog;
