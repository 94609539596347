import './style.scss'
import InputGroupCard from "../inputGroupCard/InputGroupCard";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../state/store/Store";
import {

    hamburgerMenuAddFieldReducer
} from "../../../../state/features/gameCustomisatiomContentSlice/GameCustomisationContentSlice";
import TextWithColor from "../textWithColor/TextWithColor";
import ColorPicker from "../colorPicker/ColorPicker";
import React from "react";


const HamburgerMenuGroups = () => {
    const hamburgerMenuGroupsSlice = useSelector((state: RootState) => state.GameCustomisationContentSlice.hamburgerMenuGroupsSlice);
    const dispatch = useDispatch<AppDispatch>()
    const addNewCard = () => {

        dispatch(hamburgerMenuAddFieldReducer());
    };
    const cardCount = hamburgerMenuGroupsSlice.hamburgerMenuCounter.length;
    return (
        <div className="hamburgerMenuGroups">

            <h1>Registration Fields</h1>
            <section className="hamburgerMenuGroups-card">
                {
                    hamburgerMenuGroupsSlice.hamburgerMenuCounter.map(() => (
                        <TextWithColor heading={`Menu ${cardCount} `} label={'Text'} children={''}/>
                    ))
                }
            </section>

            <section className="hamburgerMenuGroups-button">
                <button type="button" onClick={addNewCard}>+ New Menu</button>
            </section>
        </div>
    )
}


export default HamburgerMenuGroups;