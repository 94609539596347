import './style.scss'
import {colorChange} from "../../../../state/features/gameCustomisatiomContentSlice/GameCustomisationContentSlice";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../state/store/Store";
interface colorPickerInterface {
    heading: string;
    label: string;
}



const ColorPicker = ({heading,label}:colorPickerInterface) => {
    const textWithColorState = useSelector((state: RootState) => state.GameCustomisationContentSlice.textWithColorSlice);
    const dispatch = useDispatch<AppDispatch>()

  return(
      <div className='colorWith'>
          <h1>{heading}</h1>
          <label htmlFor='color-text'>{label}</label>
          <div className='colorWith-input'>
              <input
                  type="color"
                  value={textWithColorState.color}
                  onChange={(event) => {
                      dispatch(colorChange(event.target.value));
                  }}
              />
              <label htmlFor='color'>{textWithColorState.color}</label>
          </div>
      </div>
  );
}

export default  ColorPicker;