import './style.scss'
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../state/store/Store";
import {
    howToPlayMenuReducer
} from "../../../../state/features/gameCustomisatiomContentSlice/GameCustomisationContentSlice";
import TextWithColor from "../textWithColor/TextWithColor";
import React from "react";


const HowToPlayMenuGroups = () => {
    const howToPlayMenuGroupsSlice = useSelector((state: RootState) => state.GameCustomisationContentSlice.howToPlayMenuGroupsSlice);
    const dispatch = useDispatch<AppDispatch>()
    const addNewCard = () => {

        dispatch(howToPlayMenuReducer());
    };
    const cardCount = howToPlayMenuGroupsSlice.howToPlayMenuCounter.length;
    return (
        <div className="howToPlayMenuGroups">

            <h1>Registration Fields</h1>
            <section className="howToPlayMenuGroups-card">
                {
                    howToPlayMenuGroupsSlice.howToPlayMenuCounter.map(() => (
                        <TextWithColor heading={`Step ${cardCount} `} label={'Text'} children={''}/>
                    ))
                }
            </section>

            <section className="howToPlayMenuGroups-button">
                <button type="button" onClick={addNewCard}>+ New Menu</button>
            </section>
        </div>
    )
}


export default HowToPlayMenuGroups;