import './style.scss'
import ToggleSwitch from "../../../../component/toggleSwich/ToggleSwich";
import ImageInput from "../../../../component/imageInput/ImageInput";
import TextWithColor from "../../../../component/textWithColor/TextWithColor";
import ColorPicker from "../../../../component/colorPicker/ColorPicker";
import React from "react";


const IntroPage = () => {

    return (
        <div className="introPage">
            <section className="introPage-heading">
                <h1>Intro Page</h1>
                <ToggleSwitch/>
            </section>
            <ImageInput images={[
                {
                    label: "Brand  Logo",
                    image: ""
                },
                {
                    label: "Creative Image",
                    image: ""
                },
            ]}/>
            <TextWithColor heading={'Heading'} label={'Text'} children={<ColorPicker label={'Color'} heading={''}/>}/>
        </div>
    )
}


export default IntroPage;