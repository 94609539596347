import './style.scss'
import logo from '../../../../assets/landingPage/engageUsers/logo.png'

export default function NavBar(props:any) {



    return (
        <div className={'navbar'}>
            <section className={'navLeft'}>
                <img src={logo} alt={'logo'}/>
            </section>
            <section className={'navRight'}>
                <button type={'button'} onClick={()=>props.showDrawer(true)}>Login</button>
            </section>
        </div>
    )
}