import './style.scss'
import {smallPhoneCardConst} from "../../../../constants/GameCustomisationPageConsts";
import SmallPhoneCard from "../../component/smallPhoneCard/SmallPhoneCard";
import {infoMob} from "../../../../constants/images";
import IntroPage from "./section/introPage/IntroPage";
import RegistrationPage from "./section/registrationPage/RegistrationPage";
import OtpPage from "./section/otpPage/OtpPage";
import GamePage from "./section/gamePage/GamePage";
import HamburgerMenuPage from "./section/hamburgerMenuPage/HamburgerMenuPage";
import HowToPlayPage from "./section/howToPlayPage/HowToPlay";
import ContactUsPage from "./section/contactUsPage/ContactUsPage";
import TermsAndConditionsPage from "./section/termsAndConditions/TermsAndConditions";
import WinnerPage from "./section/winnerPage/WinnerPage";


export default function GameCustomisationContent() {


    return (
        <div className={'gameCustomisationContent-wrapper'}>
            <div className={'gameCustomisationContent'}>
                <section className={'gameCustomisationContent-left'}>
                    {
                        smallPhoneCardConst.map(({image, heading}) => (
                            <SmallPhoneCard src={image} heading={heading} key={`small-card-${heading}`}/>
                        ))
                    }
                </section>
                <section className={'gameCustomisationContent-middle'}>
                    <img src={infoMob} alt={'bg-mobile'}/>
                </section>
                <section className={'gameCustomisationContent-right'}>
                    <div className={'g-c-left-contentDiv'}>
                        <IntroPage/>
                        <RegistrationPage/>
                        <OtpPage/>
                        <GamePage/>
                        <WinnerPage/>
                        <HamburgerMenuPage/>
                        <HowToPlayPage/>
                        <ContactUsPage/>
                        <TermsAndConditionsPage/>
                    </div>
                    <div className="g-c-left-bottomDiv">
                        <button type="button" className="btn-back">Back</button>
                        <button type="button" className="btn-next">Next</button>
                    </div>
                </section>
            </div>
        </div>
    )
}