import {introMob} from "./images";

export const smallPhoneCardConst =[
    {
        image:introMob,
        heading:'Intro',
    },
    {
        image:introMob,
        heading:'Registration',
    },
    {
        image:introMob,
        heading:'OTP',
    },
    {
        image:introMob,
        heading:'Game',
    },
    {
        image:introMob,
        heading:'Winner Page',
    },
    {
        image:introMob,
        heading:'Hamburger Menu',
    },
    {
        image:introMob,
        heading:'How to Play?',
    },
    {
        image:introMob,
        heading:'Contact Us',
    },
    {
        image:introMob,
        heading:'T&C',
    },


]