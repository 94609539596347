import React, { useState } from "react";
import './style.scss'
import { addImageLogo } from "../../../../constants/images";
import styled from "styled-components";


interface ImageItem {
    label: string;
    image: string;
}

interface ImageUploadProps {
    images: ImageItem[];
}
export const AvatarInput = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 5px;
  }
  .default-img {
    object-fit: cover;
  }

  .circle {
    width: 100%;
    border-radius: 5px;
  }
`;

export default function ImageInput({ images }: ImageUploadProps) {
    const [urls, setUrls] = useState<string[]>([]);

    const handleFiles = async (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const file = event.target.files && event.target.files[0];

        if (file) {
            const imageUrl = await readImageAsDataURL(file);
            const updatedUrls = [...urls];
            updatedUrls[index] = imageUrl;
            setUrls(updatedUrls);
            console.log(updatedUrls);
        }
    };

    const readImageAsDataURL = (file: File): Promise<string> => {
        return new Promise<string>((resolve) => {
            const reader = new FileReader();

            reader.onload = (event) => {
                if (event.target) {
                    resolve(event.target.result as string);
                }
            };

            reader.readAsDataURL(file);
        });
    };

    return (
        <div className="imageInput-wrapper">
            {images.map((data, index) => (
                <div key={`image-${index}`} className="imageInput">
                    <h1>{data.label}</h1>
                    <div className={"input-img"}>
                        <input type="file" accept="image/*" onChange={(e) => handleFiles(e, index)} />
                        <AvatarInput>
                            {urls[index] ? (
                                <img src={urls[index]} alt="Avatar" />
                            ) : (
                                <img src={addImageLogo} alt="addImageLogo" className="default-img"/>
                            )}
                        </AvatarInput>
                    </div>
                </div>
            ))}
        </div>
    );
}
