import './style.scss'
import ToggleSwitch from "../../../../component/toggleSwich/ToggleSwich";
import React from "react";
import TextEditor from "../../../../../../components/textEditor/TextEditor";
import BackgroundChangeRadio from "../../../../component/backgroundChangeRadio/BackgroundChangeRadio";
import ColorPicker from "../../../../component/colorPicker/ColorPicker";



const ContactUsPage = () => {
  return(
      <div className="contactUsPage">
          <section className="contactUsPage-heading">
              <h1>Contact Us</h1>
              <ToggleSwitch/>
          </section>
          <TextEditor/>
          <ColorPicker label={''} heading={'Text Color'}/>
          <BackgroundChangeRadio/>
      </div>
  )
}

export default ContactUsPage;