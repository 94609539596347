import React from 'react'
import './style.scss'

interface BrandLogoLoopProps  {
    image: string[]
}

const BrandLogoLoop: React.FC<BrandLogoLoopProps> = ({image}) => {
    return (
        <>
            {image.map((image, index) => {
                return <img src={image} alt={'logo-image'} key={index}/>
            })}
        </>
    );
}
export default BrandLogoLoop;