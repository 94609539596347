import './style.scss'
import ToggleSwitch from "../toggleSwich/ToggleSwich";
import React from "react";

interface HeadingToggleProps{
    heading: string
}


const HeadingToggle = ({heading}:HeadingToggleProps) => {
  return(
      <section className="headingToggle">
          <h1>{heading}</h1>
          <ToggleSwitch/>
      </section>
  )
}
export default HeadingToggle;