import './style.scss'
import ToggleSwitch from "../../../../component/toggleSwich/ToggleSwich";
import ImageInput from "../../../../component/imageInput/ImageInput";
import ColorPicker from "../../../../component/colorPicker/ColorPicker";
import React from "react";
import HamburgerMenuGroups from "../../../../component/hamburgerMenuGroups/HamburgerMenuGroups";
import BackgroundChangeRadio from "../../../../component/backgroundChangeRadio/BackgroundChangeRadio";


const HamburgerMenuPage = () => {

    return (
        <div className="gamePage">
            <section className="gamePage-heading">
                <h1>Hamburger Menu</h1>
                <ToggleSwitch/>
            </section>
            <ImageInput images={[
                {
                    label: "Brand  Logo",
                    image: ""
                }
            ]}/>
            <HamburgerMenuGroups/>
            <ColorPicker label={''} heading={'Text Color'}/>
            <BackgroundChangeRadio/>
        </div>
    )
}


export default HamburgerMenuPage;