import './style.scss'
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import {useState} from "react";
import CampaignStepper from "../../component/campaignStepper/CampaignStepper";
import TitleDialog from "../../component/titleDialog/TitleDialog";

/*
TODO: active steps are going inactive when we clicking on active steps
*/

/*for updating steps*/
export default function GameCustomisationHeader() {

    const [activeStep, setActiveStep] = useState<number>(1);
    const [steps, setSteps] = useState<string[]>(['Design', 'Rewards', 'Analytics', 'Nudges', 'Publish']);


    const handleStepClick = (step: number) => {
        if (step <= activeStep) {
            setActiveStep(step);
        }
    };

    const buttonActionNext = () => {
        if (activeStep < steps.length) {
            setActiveStep(activeStep + 1);
        } else {
            let newSteps: string[];
            newSteps = steps;
            setSteps(newSteps);
            setActiveStep(1);
        }
    };

    /*for updating game title*/
    const [title, setTitle] = useState<string>('Untitled Campaign');
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    const handleOpenDialog = () => {
        setDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setDialogOpen(false);
    };

    const handleUpdateTitle = (newTitle: string) => {
        setTitle(newTitle);
    };



    return (
        <div className={'gameCustomizationHeader'}>
            <section className={'gameCustomizationHeader-left'}>
                <div className={'left-setting'}>
                    <div className={'left-setting-wrapper'}>
                        <h1>{title}</h1>
                        <div className={'left-setting-divider'}></div>
                        <button className={'left-setting-logo'} type={'button'} onClick={handleOpenDialog}><SettingsOutlinedIcon/></button>
                        <TitleDialog
                            open={dialogOpen}
                            onClose={handleCloseDialog}
                            onUpdateTitle={handleUpdateTitle}
                        />
                    </div>
                </div>
                <div className={'left-stepper'}>
                    <div className={'left-stepper-wrapper'}>

                        <CampaignStepper steps={steps} activeStep={activeStep} onStepClick={handleStepClick}/>

                    </div>
                </div>
            </section>

            <section className={'gameCustomizationHeader-right'}>
                <div className={'right-saveContent'}>
                    <button className={'right-saveContent-wrapper'} type={'button'} onClick={buttonActionNext}>
                        <SaveOutlinedIcon/>
                    </button>
                </div>
                <div className={'right-preview'}>
                    <div className={'right-preview-wrapper'}>
                        <button className={'right-preview-logo'} type={'button'}><PlayArrowOutlinedIcon/></button>
                        <h2>Preview</h2>
                    </div>
                </div>
            </section>
        </div>
    )
}