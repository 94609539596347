import './style.scss'
import MoreVertOutlinedIcon from '@mui/icons-material/MoreVertOutlined';
import DropdownMenu from "../dropdown/Dropdown";

interface campaignsCardProps {
    img: string,
    heading: string,
    campaignName: string,
    campaignDate: string,
}

export default function CampaignsCard(props: campaignsCardProps) {

    return (
        <div className={'campaignsCard'}>
            <div className={'campaignsCard-wrapper'}>
                <section className={'campaignsCard-img'}>
                    <img src={props.img} alt="campaign"/>
                </section>
                <section className={'campaignsCard-content'}>
                    <div className={'content-h'}>
                        <p className={'campaignsCard-ph'}>{props.heading}</p>
                        <DropdownMenu/>
                    </div>
                    <h2>{props.campaignName}</h2>
                    <p className={'campaignsCard-pd'}>Created on: {props.campaignDate}</p>
                </section>
            </div>
        </div>
    )

}