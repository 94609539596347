import './style.scss';
import InputGroupCard from "../inputGroupCard/InputGroupCard";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../../../../state/store/Store";

import {
    addFieldReducer, swapUpReducer,
    DeleteCardReducer
} from "../../../../state/features/gameCustomisatiomContentSlice/GameCustomisationContentSlice";


const InputGroups = () => {
    const inputGroupCardInterface = useSelector((state: RootState) => state.GameCustomisationContentSlice.inputGroupCardSlice);
    const dispatch = useDispatch<AppDispatch>()

    const addNewCard = () => {

        dispatch(addFieldReducer());
    };

    const inputGroupsDispatchFunctions = {
        update: (index: any) => dispatch(DeleteCardReducer(index)),
        swapUp: (index: any) => dispatch(swapUpReducer(index)),
    };

    const counterLength = inputGroupCardInterface.counter.length;


    return (
        <div className="inputGroups">
            <h1>Registration Fields</h1>
            <section className="inputGroups-card">
                {inputGroupCardInterface.counter.map((value, index) => (
                    <InputGroupCard index={index} length={counterLength} key={value} inputGroupsDispatchFunctions={inputGroupsDispatchFunctions} />
                ))}
            </section>

            <section className="inputGroups-button">
                <button type="button" onClick={addNewCard}>+ New Field</button>
            </section>

        </div>
    )
}
export default InputGroups;