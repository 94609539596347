import './style.scss'

type BoostProps = {
    heading: string,
    content: string,
    image1: string,
    image2: string,
    image3: string,
    boostRightClass: string,
    boostLeftClass: string,
    image2Class: string,
    image3Class: string
}
export default function BoostSectionDivCard({ heading, content, image1, boostRightClass, image2, image3, boostLeftClass, image2Class, image3Class}:BoostProps) {

    return(
        <div className={'boostSection'}>
            <section className={boostLeftClass}>
                <h1>{heading}</h1>
                <p>{content}</p>
            </section>
            <section className={boostRightClass}>
                <img src={image1}/>
                <img src={image2} className={image2Class}/>
                <img src={image3} className={image3Class}/>
            </section>
        </div>
    )
}