import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';


const primaryDark = '#FFFFFF';
const primaryPale = '#F6F8F8';
const primaryLight = '#E0E7EB';
const greenPrimary  = '#30A46C'

const BaseSwitch = styled(Switch)({
    width: 45,
    height: 22,
    padding: 0,
    display: 'flex',
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(23px)',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: greenPrimary
            }
        }
    },
    '& .MuiSwitch-thumb': {
        width: 18,
        height: 18,
        borderRadius: '25px',
        backgroundColor: primaryDark
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        borderRadius: '25px',
        border: `1px solid ${primaryLight}`,
        backgroundColor: primaryPale,
        boxSizing: 'border-box'
    }
});


export default function ToggleSwitch() {
    return (
        <BaseSwitch />
    );
}
