import './style.scss'
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import React, {useState} from "react";
import ColorPicker from "../colorPicker/ColorPicker";
import ImageInput from "../imageInput/ImageInput";

const bgcolor = {
    lightgray: 'D7DBDF',
    bgpurple: '6E56CF',
}

const BackgroundChangeRadio = () => {
    const [value, setValue] = useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    return (
        <FormControl>
            <h1>Background</h1>
            <RadioGroup
                row
                aria-labelledby="row-radio-buttons-group-label"
                name="row-radio-buttons-group"
                value={value}
                onChange={handleChange}
            >
                <FormControlLabel value="color" control={<Radio/>} label="Color" sx={{
                    color: bgcolor.lightgray,
                    '&.Mui-checked': {
                        color: bgcolor.bgpurple,
                    },
                }}/>
                <FormControlLabel value="image" control={<Radio/>} label="Image" sx={{
                    color: bgcolor.lightgray,
                    '&.Mui-checked': {
                        color: bgcolor.bgpurple,
                    },
                }}/>
            </RadioGroup>
            <div className="bg-content">

                {value === 'color' ? <ColorPicker heading={''} label={''}/> : null}
                {value === 'image' ? <ImageInput images={[
                    {
                        label: "",
                        image: ""
                    }

                ]}/> : null}

            </div>
        </FormControl>
    );
}

export default BackgroundChangeRadio;